<template>
	<div>
		<el-card class="mt-2 ml-4 mr-4 bg-white border-white">

			<div style="width: 100%; margin: auto; " class="font-semibold text-lg">
				<el-divider direction="vertical" style="color: #7d89c3;width: 5px;"></el-divider>管理员管理
			</div>
			<!-- 查询框 -->
			<el-form :model="searchData" class="demo-form-inline mt-4 pl-6 pr-6" :label-width="labelWidth">
				<el-row class="flex">
					<el-input class="mr-2" v-model="searchData.name" size="small" style="width: 12rem;"
						placeholder="幼儿园名称">
					</el-input>
					<el-select v-model="searchData.region" size="small" style="width:20%;margin-left: 30px;" placeholder="请选择园区"
						@change="selectRegion">
						<el-option v-for="item in regionOptions" :key="item.id" :label="item.name" :value="item">
						</el-option>
					</el-select>
					<el-button type="primary" plain size="small" style="background-color: #706fa8c3;color: white;border-color: initial;"
						icon="el-icon-search" @click="search()">搜索</el-button>
					<el-button type="primary" size="small" style="background-color: white;color: black;border-color: initial;"
						icon="el-icon-refresh" plain @click="reset()">重置</el-button>
					<!-- <el-button type="primary" size="small" style="background-color: #000000a6;color: white;"
					v-if="role=='super'"
                        icon="el-icon-sort" @click="(dialog=true),(form={})" plain>导入</el-button> -->
				<template slot="label">
					请选择园区
				</template>
				
				
				</el-row>
			
			</el-form>
			<div class="yunaqu" style="margin-top: 1rem;">
				<el-descriptions class="margin-top" title="" style="width: 100%;" :column="1" size="small" border>
					
				</el-descriptions>
			</div>
			<!-- 表格 -->
			<el-tabs v-model="activeName" @tab-click="handleClick" class="mt-4 ml-4">
				<el-tab-pane :label="'全部'+tabTitle.all" name="first">
					<el-table :data="tableData" border style="width: 100%;margin-top: -1rem;">
						<el-table-column prop="id" label="" min-width="5%">
						</el-table-column>
						<el-table-column prop="name" label="幼儿园名称" min-width="20%">
						</el-table-column>
						<el-table-column prop="count" label="园区数" min-width="10%">
						</el-table-column>
						<el-table-column prop="state" label="园区导入状态" min-width="10%">
						</el-table-column>
						<el-table-column prop="region" label="地区" min-width="10%">
						</el-table-column>
						<el-table-column prop="update_time" label="更新时间" min-width="15%">
						</el-table-column>
						<!-- <el-table-column prop="status" label="更新幼儿园名称" width="150">
                            <template slot-scope="scope">
                                <el-button size="small" type="text">更新</el-button>
                            </template>
                        </el-table-column> -->
						<el-table-column label="园区详情" min-width="10%">
							<template slot-scope="scope">
								<el-button size="" type="text" @click="(editDialog=true),(row=scope.row),(isPassword=true)">编辑</el-button>
								<el-divider direction="vertical" style="width: 2px;"></el-divider>
								<el-button size="" type="text" style="color: red;" @click="handleDel(scope.row)">删除</el-button>
							</template>
						</el-table-column>
					</el-table>
				</el-tab-pane>
			</el-tabs>
			<!-- <div class="flex justify-end text-xs mt-4">*该表格的已到状态会在当日的24:00后自动更新</div> -->
			<!-- 分页 -->
			<div class="flex  bg-white ml-4 mt-6 pt-4 pb-4">
				<div></div>
				<Pagination :currentPage="currentPage" :pageSize="pageSize" :total="total" @pageChange="pageChange" />
			</div>
		</el-card>

		<!-- 修改密码对话框 -->
		<el-dialog :visible.sync="passwordDialog"  width="600px" style="height:450px;overflow: hidden;" title="修改密码"
			:close-on-click-modal="false">
			<template v-if="isPassword">
			<!-- 分割线 -->
			<div class=" ml-6 mr-6  mb-4 mt-4">
				<el-descriptions class="margin-top" title="" :column="1" border>
					<el-descriptions-item>
						<template slot="label">
							<i class="el-icon-user"></i>
							请输入原密码
						</template>
						<el-input placeholder="" v-model="password.old" show-password></el-input>
					</el-descriptions-item>
					<el-descriptions-item>
						<template slot="label">
							<i class="el-icon-mobile-phone"></i>
							请输入新密码
						</template>
						<el-input placeholder="" v-model="password.new" show-password></el-input>
					</el-descriptions-item>
					<el-descriptions-item>
						<template slot="label">
							<i class="el-icon-mobile-phone"></i>
							请确认新密码
						</template>
						<el-input placeholder="" v-model="password.newCofirm" show-password></el-input>
					</el-descriptions-item>
				</el-descriptions>
			</div>
			<div class="flex justify-center items-center">
				<el-button class="mr-10" type="info" @click="passwordDialog=false">取消</el-button>
				<el-button type="primary" @click="passwordSubmit()">确认</el-button>
			</div>
			</template>
			<template v-else>
				<div style="">
					<div style="padding:20px;font-size:2rem;color:aqua;">
					旧的链接将在下一次使用的时候时效，请记录好新的链接:
					</div>
					<div style="padding:20px;font-size:1.5rem;color:blue">{{newLink}}</div>
				</div>
			</template>
		</el-dialog>
		<el-dialog :visible.sync="editDialog" width="500px" style="height:250px;overflow: hidden;"  title="请选择编辑的类型"
			:close-on-click-modal="false">
			<div class="mt-6 ">
				<div class=" mt-4" style="justify-content: space-between;display:flex">
					<el-button type="primary" class="ml-16 " @click="passwordDialog=true">修改密码</el-button>
					<el-button type="info" class="mr-16" @click="(dialog=true),handleEdit(row)">修改配置</el-button>
				</div>
			</div>
		</el-dialog>
		<!-- 信息配置对话框 -->
		<el-dialog :visible.sync="dialog" width="700px"  title="导入学校信息编辑" :close-on-click-modal="false">
			<template>
				<!-- 分割线 -->
				<el-divider></el-divider>
				<div class=" ml-6 mr-6 mt-6 mb-4">
					<div style="width: 100%; margin: auto; " class="font-semibold text-sm">
						<el-divider direction="vertical" style="color: #7d89c3;width: 8px;"></el-divider>填写信息
					</div>
					<div class="ml-4 mr-6 mt-2 mb-8 flex">
						<el-form :model="form" class="demo-form-inline mt-4  pr-6" :label-width="labelWidth">
							<el-row>
								<el-col :span="7" class="flex">
									<el-form-item label="aes_key :">
										<el-input size="small" v-model="form.aes_key" placeholder="请输入"
											style="width: 200px;"></el-input>
									</el-form-item>
									<el-form-item label="Corpid :">
										<el-input size="small" v-model="form.corp_id" placeholder="请输入"
											style="width: 200px;"></el-input>
									</el-form-item>
								</el-col>
							</el-row>
							<el-row>
								<el-col :span="7" class="flex">
									<el-form-item label="Agentid :">
										<el-input size="small" v-model="form.agent_id" placeholder="请输入"
											style="width: 200px;"></el-input>
									</el-form-item>
									<el-form-item label="Appkey :">
										<el-input size="small" v-model="form.app_key" placeholder="请输入"
											style="width: 200px;"></el-input>
									</el-form-item>
								</el-col>
							</el-row>
							<el-row>
								<el-col :span="7" class="flex">
									<el-form-item label="AppSecret :">
										<el-input size="small" v-model="form.app_secret" placeholder="请输入"
											style="width: 200px;"></el-input>
									</el-form-item>
									<el-form-item label="签名token :">
										<el-input size="small" v-model="form.token" placeholder="请输入"
											style="width: 200px;"></el-input>
									</el-form-item>
								</el-col>
							</el-row>
							<el-row>
								<el-col :span="7" class="flex">
									<el-form-item label="集团名称 :">
										<el-input size="small" v-model="form.name" placeholder="请输入"
											style="width: 200px;"></el-input>
									</el-form-item>
									<el-form-item label="跳转链接 :">
										<el-input size="small" v-model="form.link" placeholder="请输入" readonly="readonly"
											style="width: 200px;"></el-input>
									</el-form-item>
								</el-col>		
							</el-row>
							<el-row>
								<el-col :span="7" class="flex">
									<el-form-item label="账号 :">
										<el-input size="small" v-model="form.account" placeholder="请输入" readonly="readonly"
											style="width: 200px;"></el-input>
									</el-form-item>
									<el-form-item label="密码 :">
										<el-input size="small" v-model="form.password" placeholder="请输入" readonly="readonly"
											style="width: 200px;"></el-input>
									</el-form-item>
								</el-col>		
							</el-row>
							<el-row>
								<el-col :span="7" class="flex">
									<el-form-item label="区县 :">
                                        <el-select v-model="form.region_id" placeholder="请选择" size="small" style="width:200px" @change="areaChange">
                                            <el-option
                                                v-for="item in options"
                                                :key="item.id"
                                                :label="item.name"
                                                :value="item.id"
                                            ></el-option>
                                        </el-select>
                                    </el-form-item>
								</el-col>		
							</el-row>
						</el-form>
					</div>
					<div class="mt-6 ">
						<el-divider style="color: #7d89c3;width: 5px;"></el-divider>
						<div class="flex justify-end mt-4">
							<el-button type="primary" size="small" class="mr-8 " @click="importData(),(editDialog=false)">确定</el-button>
							<el-button type="info" size="small" class="mr-8" @click="dialog=false">取消</el-button>
						</div>
					</div>
				</div>
			</template>
		</el-dialog>

	</div>
</template>

<script>
	import Pagination from "../../components/Pagination/pagination";
	export default {
		data() {
			return {
				form: {
					imgUrl: "", //图片字段
					platName: "", //自定义字段
				},
				activeName: 'first',
				labelWidth: "100px",
				searchData: {
					name: '',
					createTime: [],
					region:''
				},
				options:[],
				form: {
					//编辑框里面的字段
					name: '',
					token: '',
					app_key: '',
					app_secret: '',
					agent_id: '',
					corp_id: '',
					aes_key: '',
					account: '',
					password: '',
					link:'',
					region_id:''
				},
				password: {
					new: '',
					old: '',
					newCofirm: ''
				},
				tableData: [],
				// 对话框
				isAdd: false, //区分添加和编辑
				dialog: false,
				editDialog: false,
				// 分页
				total: 0,
				currentPage: 1,
				pageSize: 10,
				tabIndex: 0,
				Loading: 0,
				//标题显示数量
				tabTitle: {
					all: 0,
				},
				role: '',
				school_id: '',
				passwordDialog: false, //修改密码框
				row: '', //存储当前行的数据
				newLink:'',
				isPassword:true,
				regionOptions:'' ,// 搜索栏区县下拉框,
				regionId:'', // 搜索框区县id
			};
		},
		computed: {},
		components: {
			Pagination
		},
		watch: {},
		async mounted() {},
		created() {
			this.role = window.sessionStorage.getItem("role")
			this.school_id = window.sessionStorage.getItem("school_id")
			this.getArea()
			this.getList()
		},
		methods: {
			areaChange(e){
            console.log(e)
        },
        //获取区县
        async getArea(){
            const res = await this.http.post("admin/impschool/regionList")
            this.options = res.data
			this.regionOptions = res.data
        },
			//幼儿园集团列表
			async getList() {
				const res = await this.http.post("admin/impschool/list", {
					limit: this.pageSize,
					page: this.currentPage,
					key: this.tabIndex,
					role: this.role,
					school_id: this.school_id == "undefined" ? "" : this.school_id
				})
				this.total = res.total
				this.tableData = res.data
				this.tabTitle.all = res.all
			},
			handleClick(tab, event) {
				this.tabIndex = tab.index
				this.currentPage = 1
				if (this.searchData.name != '') {
					this.commonData()
				} else {
					this.getList()
				}
			},
			//修改密码
			async passwordSubmit() {
				console.log(this.row)
				let old = this.row.password
				if (this.password.new != this.password.newCofirm) {
					this.$message.error('两次输入的密码不相同，请重新输入');
				} else if (old != this.password.old) {
					this.$message.error('原密码输入错误，请重新输入');
				}else if(old == this.password.new){
					this.$message.error("新密码跟原密码相同,请重新输入")
				}else {
					const res = await this.http.post("admin/changePassword", {
						account: this.row.account,
						newpassword: this.password.new,
						admin_id: this.row.admin_id,
						school_id: this.row.id
					})
					if (res.code == 200) {
						this.isPassword=false
						this.$message({
							message: '密码修改成功',
							type: 'success'
						});
						this.newLink=res.data
						this.getList()
						// this.$refs.child.logout()
					}
				}
			},
			//重置
			reset() {
				this.searchData.name = ""
				this.pageSize = 10
				this.currentPage = 1
				this.tabIndex = 0
				this.searchData.region=''
				this.regionId=''
				this.activeName = "first"
				this.getList()
			},
			//删除
			handleDel(row){
				this.$confirm("是否删除", "提示", {
				  confirmButtonText: "确定",
				  cancelButtonText: "取消",
				  type: "warning",
				})
				  .then(async () => {
				    const RES = await this.http.post("admin/impschool/delete", {
				      school_id: row.id,
					  admin_id:row.admin_id
				    });
				    if (RES.code == 200) {
				      this.getList();
				      this.$message({
				        type: "success",
				        message: "删除成功!",
				      });
				    }else if(RES.code==400){
						this.$message.error(RES.msg)
					}
				  })
				  .catch(() => {
				    this.$message({
				      type: "info",
				      message: "已取消删除",
				    });
				  });
			},
			//导入
			async importData() {
				var that = this
				this.form.phone = '1'
				this.form.school_id = this.form.id
				this.form.state = '1'
				if (this.check.checkHasEmpty(this.form)) {
					this.$message({
						type: 'warning',
						message: '必须全部填写'
					})
				} else {
					console.log(this.form)
					const res = await this.http.post("admin/impschool/update", this.form)
					if (res.code == 200) {
						this.dialog = false
						this.$message.success("更新成功")
					} else {
						this.$message({
							type: 'warning',
							message: res.msg
						})
					}
					this.getList()
				}
			},
			// 编辑
			handleEdit(row) {
				var that = this
				this.dialog = true
				this.form = JSON.parse(JSON.stringify(row)); //编辑给表单赋值的时候，需要把数据深度拷贝
				this.form.account=row.account
				this.form.password=row.password
				this.options.map(x=>{
					if(x.id==row.region_id){
						that.form.region_id=x.name
					}
				})
			},
			//分页
			pageChange(index) {
				this.currentPage = index._currentPage
				this.pageSize = index._pageSize
				if (this.check.checkEmpty(this.searchData)) {
					//检查查询字段是否都为空，都为空则表示普通分页，不都为空则表示分页查询
					this.getList();
				} else {
					this.commonData();
				}
			},
			// 确认
			handleConfirm() {
				var a = {
					imgUrl: this.form.imgUrl,
					platName: this.form.platName
				}; //检查必填字段
				if (this.check.checkHasEmpty(a)) {
					//调用函数checkHasEmpty() 检查必填字段是否有为空的
					this.$message("请将数据填写完整");
				} else {
					this.addImg();
				}
			},
			//查询
			search() {
				this.currentPage = 1;
				this.tabIndex = 0
				this.activeName = "first"
				this.commonData();
			},
			//获取下拉框数据
			selectRegion(e) {
				console.log(e)
				this.searchData.region=e.name
				this.regionId = e.id
			},
			// 分页&&查询通用数据 分页查询的情况比较多 ，坑比较多 ，建议和我写成一样或者自我优化
			async commonData() {
				if (this.searchData.createTime == null) {
					this.searchData.createTime = [];
				}
				const RES = await this.http.post("admin/impschool/search", {
					limit: this.pageSize,
					page: this.currentPage,
					name: this.searchData.name,
					key: this.tabIndex,
					role: this.role,
					region_id:this.regionId
				});
				if (RES.code == 200 || RES.code == 204) {
					this.tableData = RES.data;
					this.total = RES.total;
					this.tabTitle.all = RES.all
					this.tabTitle.run = RES.run
					this.tabTitle.empty = RES.empty
					this.tabTitle.finish = RES.finish
				}
				if (RES.code == 400) {
					this.$message({
					    message: '暂无数据，请重新选择查询条件',
					    type: 'warning'
					});
				}
			},
		},
	};
</script>
<style lang="scss" scoped>
	/deep/.el-dialog__headerbtn .el-dialog__close {
		color: black;
	}

	/deep/.el-dialog {
		height: 500px;
	}

	/deep/.el-dialog__header {
		background-color: #ffffff;
		padding: 10px 10px 10px;
	}

	/deep/.el-tabs__header {
		background-color: #d9dceca3;
	}

	/deep/.el-dialog__headerbtn {
		top: 10px;
	}

	/deep/.el-dialog__title {
		color: white;
		font-size: 1rem;
	}

	/deep/.el-table th {
		background-color: white !important;
	}

	/deep/.el-tabs__nav {}

	/deep/.el-tabs__item.is-active {
		background-color: #d4caf1;
		color: #535c8d;
	}

	/deep/.el-tabs__active-bar {
		background-color: #535c8d;
		width: 55px !important;
	}

	/deep/.el-table td,
	.el-table th {
		text-align: center;
	}

	/deep/.el-tabs--top .el-tabs__item.is-top:nth-child(2) {
		padding-left: 20px;
		padding-right: 20px;
	}

	/deep/.el-table th {
		text-align: center;
		background: rgb(236, 235, 235);
	}

	/deep/ .avatar-uploader .el-upload {
		border: 1px dashed hsl(0, 0%, 85%);
		cursor: pointer;
		position: relative;
		overflow: hidden;
		background: rgb(204, 204, 204);
	}

	/deep/.avatar-uploader .el-upload:hover {
		border-color: #409eff;
	}

	/deep/ .avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 200px;
		height: 150px;
		line-height: 150px;
		text-align: center;
	}

	/deep/ .avatar {
		width: 200px;
		height: 150px;
		display: block;
	}

	/deep/.el-form-item {
		margin-bottom: 5px;
	}

	/deep/.el-dialog__body {
		padding: 0px;
	}

	/deep/.el-divider--horizontal {
		margin: 0px;
	}


	/deep/.el-tabs__header {
        background-color: #f8f8f8;
    }

      /deep/.el-tabs__item.is-active {
        background-color: #ecedf3;
        color: #535c8d;
    }
    /deep/.el-dialog__header {
        background-color: #7285bc;
        padding: 10px 10px 10px;
    }
    /deep/.el-tabs__active-bar {
        background-color: #7d89c3;
        // margin-left: -1.8rem;
    }
</style>
